<template>
  <div class="searchWrapper">
    <!-- <el-button class="right"
              style="width: 88px; height: 36px; margin-top: -46px;"
              @click="route">返回
    </el-button> -->
    <!--列表区域-->
    <div class="tableWrapper">
      <el-table border
                
                v-loading="loading"
                :data="tableData"
                style="width: 100%;"
                height="250"
                >
        <el-table-column align='center'
                        :prop="item.prop"
                        :label="item.label"
                        :width="item.width"
                        v-for="item in tableCols"
                        :key="item.prop"
                        :formatter="item.formatter"></el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <div class="pagerWrapper"
        style="float: right;">
      <div class="block">
        <el-pagination @current-change="handleCurrentChange"
                      :current-page="pageNum"
                      :page-size="pageSize"
                      layout="total, prev, pager, next, jumper"
                      :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDuring } from "@/common/js/public";
export default {
  props: {
    carId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

      pageNum: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: 'plateNumber',
          label: '车牌号',
          width: ''
        }, {
          prop: 'parkName',
          label: '停车场',
          width: ''
        }, {
          prop: 'entryTime',
          label: '入场时间'
        }, {
          prop: 'exitTime',
          label: '出场时间'
        }, {
          prop: 'parkTime',
          label: '停车时长',
          width: '',
          formatter: (row) => {
            return formatDuring(row.parkTime)
            // if (row.parkTime) {
               
            //    return row.parkTime + '分钟'
            // } else {
            //    return '0' + '分钟'
            // }
          }
        }, {
          prop: 'ArrearageAmount',
          label: '欠费金额',
          width: '',
          formatter: (row, column) => {
            if (row.ArrearageAmount) {
              return Number((row.ArrearageAmount) / 100).toFixed(2)
            } else {
              return '0.00'
            }
          }
        }
      ]
    }
  },
  methods: {
    route () {
      this.$router.push('/arrearagePlateList')
    },
    handleCurrentChange (val) {
      this.pageNum = val;
      this.getData(this.$route.query.data)
    },
    getData () {
      this.$axios.get('/acb/2.0/debtStatistics/arrearageplatedetail', {
        data: {
          carId: this.carId,
          pageNumber: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(res => {
        this.tableData = res.value.list;
        this.total = res.value.total * 1 || 0;
      }).catch(err => {
        this.total = 0
        this.tableData = []
        // console.log(err)
      })
    }
  },
  watch: {
    carId: {
      handler() {
        this.pageNum = 1;
        this.pageSize = 15;
        this.getData()
      },
      immediate: true
    }
  },
  created () {
    },
  mounted () {
  }

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper
  // margin-top 35px
  padding-bottom 50px;
</style>
